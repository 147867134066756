@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
body {
  margin: 0;
  font-family: "Open Sans Hebrew", sans-serif;
  color: #383E4A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: Open Sans Hebrew;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  height: 43px;
  background: #FFFFFF;
  border: 0.5px solid rgb(80, 69, 75, 0.3);
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: 300;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.003em;
  color: #50454B;
  padding-left: 5%;
}

input:focus {
  outline-width: 0;
}

input::-webkit-input-placeholder {
  opacity: 0.5;
}

input::-moz-placeholder {
  opacity: 0.5;
}

input:-ms-input-placeholder {
  opacity: 0.5;
}

input::-ms-input-placeholder {
  opacity: 0.5;
}

input::placeholder {
  opacity: 0.5;
}

.SearchBar {
  height: 43px;
  width: 100%;
}

.SearchBarInHeader {
  height: 43px;
  width: 100%;
}

.SearchBarInHeader input {
  width: 100%;
}

.SearchBar input {
  width: 100%;
}

.search-button {
  border: none;
  width: 25px;
  vertical-align: middle;
  height: 25px;
  margin: auto;
  margin-left: -40px;
  background: white;
  cursor: pointer !important;
  padding: 0;
}

.search-container {
  width: 90%;
  display: inline-flex;
  flex-direction: row;
}

.search-icon {
  width: 25px;
  height: 25px;
  margin: 0;
}

#react-autowhatever-1 ul {
  padding-left: 7.5% !important;
}
.search-area {
  margin: 9% 30% 16% 30%;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 82px;
  letter-spacing: -0.003em;
  color: #383e4a;
  margin: 0 auto;
  margin-bottom: 1.5%;
}

h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #383E4A;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5%;
}

@media only screen and (max-width: 768px) {
  
  .search-area {
    margin: 15% 10% 20% 10%;
  }

  h1 {
    font-size: 48px;
    line-height: 60px;
  }

  h4 {
    font-size: 16px;
  }

}

.highlighted-text {
  font-size: 1em;
  background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%), #5DB1BA !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.Navbar {
  font-family: Open Sans Hebrew;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.003em;
}

.footer-d5 {
  margin: auto;
}

.d5-logo {
  width: 32px;
  vertical-align: middle;
  margin: 5px;
}

.by-d5 {
  vertical-align: middle;
  height: 32px;
  margin: 5px;
}

.nof-records-updated {
  margin-top: 24px;
  min-height: 16px;
  font-size: 0.9em;
  z-index: -1;
  position:relative;
}

.by-d5 a {
  color: #383E4A;
}

.by-d5 a:hover {
  color: #5DB1BA;
}
.horizontal-line {
  position: absolute;
  margin-left: -40px;
  width: 1440px;
  height: 0px;
  bottom: 0px;
  opacity: 0.5;
  border: 0.5px solid #666666;
  z-index: -1;
}

.navbar {
  height: 120px;
  margin-left: 3%;
  padding-left: 0;
}

.logo {
  font-weight: bold;
  font-size: 1.8em;
  color: #50454B !important;
}

.header-search-bar {
  width: 600px !important;
}

.search-header-container {
  width: 600px !important;
}

.SearchResultItem {
  margin-top: 1%;
  margin-bottom: 1%;
  max-width: 800px;
}
.SearchResults {
  margin: 3%;
  text-align: left;
  min-height: 480px;
}

.addresses-found {
  font-style: italic;
}

.next-page-link {
  display: flex;
  margin-top: 32px;
}
