.search-area {
  margin: 9% 30% 16% 30%;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 82px;
  letter-spacing: -0.003em;
  color: #383e4a;
  margin: 0 auto;
  margin-bottom: 1.5%;
}

h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #383E4A;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5%;
}

@media only screen and (max-width: 768px) {
  
  .search-area {
    margin: 15% 10% 20% 10%;
  }

  h1 {
    font-size: 48px;
    line-height: 60px;
  }

  h4 {
    font-size: 16px;
  }

}

.highlighted-text {
  font-size: 1em;
  background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%), #5DB1BA !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.Navbar {
  font-family: Open Sans Hebrew;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.003em;
}

.footer-d5 {
  margin: auto;
}

.d5-logo {
  width: 32px;
  vertical-align: middle;
  margin: 5px;
}

.by-d5 {
  vertical-align: middle;
  height: 32px;
  margin: 5px;
}

.nof-records-updated {
  margin-top: 24px;
  min-height: 16px;
  font-size: 0.9em;
  z-index: -1;
  position:relative;
}
