body {
  font-family: Open Sans Hebrew;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  height: 43px;
  background: #FFFFFF;
  border: 0.5px solid rgb(80, 69, 75, 0.3);
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: 300;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.003em;
  color: #50454B;
  padding-left: 5%;
}

input:focus {
  outline-width: 0;
}

input::placeholder {
  opacity: 0.5;
}

.SearchBar {
  height: 43px;
  width: 100%;
}

.SearchBarInHeader {
  height: 43px;
  width: 100%;
}

.SearchBarInHeader input {
  width: 100%;
}

.SearchBar input {
  width: 100%;
}

.search-button {
  border: none;
  width: 25px;
  vertical-align: middle;
  height: 25px;
  margin: auto;
  margin-left: -40px;
  background: white;
  cursor: pointer !important;
  padding: 0;
}

.search-container {
  width: 90%;
  display: inline-flex;
  flex-direction: row;
}

.search-icon {
  width: 25px;
  height: 25px;
  margin: 0;
}

#react-autowhatever-1 ul {
  padding-left: 7.5% !important;
}