.horizontal-line {
  position: absolute;
  margin-left: -40px;
  width: 1440px;
  height: 0px;
  bottom: 0px;
  opacity: 0.5;
  border: 0.5px solid #666666;
  z-index: -1;
}

.navbar {
  height: 120px;
  margin-left: 3%;
  padding-left: 0;
}

.logo {
  font-weight: bold;
  font-size: 1.8em;
  color: #50454B !important;
}

.header-search-bar {
  width: 600px !important;
}

.search-header-container {
  width: 600px !important;
}
