.SearchResults {
  margin: 3%;
  text-align: left;
  min-height: 480px;
}

.addresses-found {
  font-style: italic;
}

.next-page-link {
  display: flex;
  margin-top: 32px;
}